import { EActivity } from './enums/EActivity';
import { EPage } from './components/pages/EPage';
import { EGizmoReason } from './enums/EGizmoReason';
import { EMiniGame } from './enums/EMiniGame';
import { EStage } from './enums/EStage';
import { EGameStartedState } from './enums/EGameStarted';

export type Player = {
  id: number;
  name: string;
  score: number;
  is_active: boolean;
};

export type Answer = {
  id: number;
  player_id: number;
  value: string;
};

class SessionState {
  currentPage: EPage | undefined;
  roomCode: string | undefined;
  playerId: number | undefined;
  hasPassword: boolean | undefined;
  allowSpectators: boolean | undefined;
  players: Player[] | undefined;
  answers: Answer[] | undefined;
  answerStartWithRequirements: string[] | undefined;
  currentGameStartedState: EGameStartedState | undefined;
  currentMiniGame: EMiniGame | undefined;
  currentStage: EStage | undefined;
  currentActivity: EActivity | undefined;
  activityText: string | undefined;
  activityOptions: string[] | undefined;
  gizmoReason: EGizmoReason | undefined;
  
  constructor() {
    this.clear();
  }

  clear = () => {
    this.roomCode = '';
    this.playerId = 0;
    this.hasPassword = false;
    this.allowSpectators = false;
    this.players = [];
    this.answers = [];
    this.answerStartWithRequirements = [];
  };

  isPlayer = () => {
    return !!this.playerId;
  };
}

export default new SessionState();
