import React, { useEffect, useState } from 'react';
import io, { Socket } from 'socket.io-client';
import RoomCodePage from './components/pages/RoomCodePage';
import ErrorNotification from 'src/components/common/ErrorNotification';
import { EPage } from 'src/components/pages/EPage';
import LoginPage from 'src/components/pages/LoginPage';
import SessionState, { Answer } from 'src/SessionState';
import WaitingPage from 'src/components/pages/WaitingPage';
import { ENetworkEvent } from 'src/network/enums/ENetworkEvent';
import { errorMessagesForEvent } from 'src/network/ErrorMessages';
import AnswerPage from './components/pages/AnswerPage';
import VotePage from './components/pages/VotePage';
import SpectatorsWelcomePage from './components/pages/SpectatorsWelcomePage';
import { EActivity } from './enums/EActivity';
import ActivityPage from './components/pages/ActivityPage';
import { EPlayerType } from './enums/EPlayerType';
import ReactionPage from './components/pages/ReactionPage';
import ThankYouPage from './components/pages/ThankYouPage';
import WaitingPostAnswerPage from './components/pages/WaitingPostAnswerPage';
import GizmoPage from './components/pages/GizmoPage';
import { EMiniGame } from './enums/EMiniGame';
import { EStage } from './enums/EStage';
import { EGameStartedState } from './enums/EGameStarted';

function useForceUpdate() {
  const [, setToggle] = useState(false);
  return () => setToggle(toggle => !toggle);
}

function App() {
  const [socket, setSocket] = useState<Socket>();
  const [error, setError] = useState<string | undefined>();
  const [page, setPage] = useState<EPage>(EPage.Enter);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const url = process.env.REACT_APP_LOCAL ? `http://${window.location.hostname}:8080` : '';
    const newSocket = io(url);
    setSocket(newSocket);
    listenEvents(newSocket);
    return () => {
      if (socket) {
        forgetEvents(socket);
        socket.close();
      }
    };
  }, [setSocket]);
  
  const listenEvents = (socket: Socket) => {
    if (socket) {
      socket.on(ENetworkEvent.Connect, () => {
        setError(undefined);
      });
      socket.on(ENetworkEvent.Disconnect, () => {
        SessionState.clear();
        setPage(EPage.Enter);
        setError(errorMessagesForEvent[ENetworkEvent.Disconnect]);
      });
      socket.on(ENetworkEvent.RoomClosed, () => {
        SessionState.clear();
        setPage(EPage.Enter);
        setError(errorMessagesForEvent[ENetworkEvent.RoomClosed]);
      });
      socket.on(
        ENetworkEvent.RequestAnswer,
        (
          answer_start_requirements: string[],
          current_mini_game: EMiniGame,
          current_activity: EActivity,
          activity_text: string,
          activity_options: string[],
        ) => {
          if (SessionState.currentPage === EPage.Login) {
            return;
          }
          
          SessionState.answerStartWithRequirements = answer_start_requirements; 
          // TODO: Remove the need for this initial value of empty string
          if (SessionState.answerStartWithRequirements.length === 0) {
            SessionState.answerStartWithRequirements.push("");
          }
          SessionState.currentMiniGame = current_mini_game;
          SessionState.currentStage = EStage.Answer;
          
          if (SessionState.isPlayer()) {
            setPage(EPage.Answer);
          } else {
            const isSame =
              SessionState.currentActivity === current_activity &&
              SessionState.activityText === activity_text;
            if (!isSame) {
              SessionState.currentActivity = current_activity;
              SessionState.activityText = activity_text;
              SessionState.activityOptions = activity_options;
              setPage(EPage.Activity);
            }
          }
        },
      );
      socket.on(
        ENetworkEvent.RequestVote,
        (current_round: number, current_phase: number, answers: Answer[]) => {
          if (SessionState.currentPage === EPage.Login) {
            return;
          }
          
          SessionState.answers = answers;
          SessionState.currentStage = EStage.Vote;
          
          const isPlayer: boolean = SessionState.isPlayer();
          if (!isPlayer) {
            if (current_phase == 1) {
              setPage(EPage.Reaction);
            } else if (current_phase == 0) {
              if (current_round % 2 == 1) {
                setPage(EPage.Reaction);
              } else {
                setPage(EPage.Vote); 
              }
            }
          } else {
            setPage(EPage.Vote);
          }
        },
      );
      socket.on(ENetworkEvent.RequestWaiting, (type: EPlayerType, game_started_state: EGameStartedState,
                                               current_stage: EStage) => {
        const isPlayer = SessionState.isPlayer();
        if (type == EPlayerType.Player && isPlayer) {
          SessionState.currentGameStartedState = game_started_state;
          SessionState.currentStage = current_stage;
          setPage(EPage.Waiting);
          // Forces re-render if already on the waiting page, in case the title changes
          forceUpdate();
        } else if (type == EPlayerType.Spectator && !isPlayer && SessionState.currentPage != EPage.ThankYou) {
          SessionState.currentGameStartedState = game_started_state;
          SessionState.currentStage = current_stage;
          setPage(EPage.Waiting);
        }
      });
    }
  };

  const forgetEvents = (socket: Socket) => {
    if (socket) {
      socket.off(ENetworkEvent.Connect);
      socket.off(ENetworkEvent.Disconnect);
      socket.off(ENetworkEvent.RoomClosed);
      socket.off(ENetworkEvent.RequestAnswer);
      socket.off(ENetworkEvent.RequestVote);
      socket.off(ENetworkEvent.RequestWaiting);
    }
  };

  const getHeader = (title: string) => {
    return (
      <div className="columns">
        <div className="column">
          <h1 className="title">{title}</h1>
        </div>
        <div className="column">
          <h2 className="subtitle has-text-right">
            {SessionState.roomCode?.toUpperCase()}
          </h2>
        </div>
      </div>
    );
  };
  
  const getWaitingTitle = () => {
    if (SessionState.isPlayer()) {
      switch (SessionState.currentGameStartedState) {
        case EGameStartedState.Waiting: {
          return 'START ONCE EVERYBODY\'S IN!';
        }
        case EGameStartedState.Starting: {
          return 'THE GAME IS STARTING';
        }
        case EGameStartedState.Running: {
          switch (SessionState.currentStage) {
            case EStage.Prompt: {
              return 'WATCH THE SCREEN!';
            }
            case EStage.Answer: {
              return 'WAITING FOR OTHER PLAYERS';
            }
            case EStage.Reveal: {
              return 'WATCH THE ANSWERS!';
            }
            case EStage.Vote: {
              return 'WAITING FOR OTHER PLAYERS';
            }
            case EStage.Score: {
              return 'SEE WHO GOT POINTS!';
            }
          }
          
          break;
        }
        case EGameStartedState.Ended: {
          return 'GOOD GAME, EVERYBODY!';
        }
      }
    }
    return 'WAITING';
  };
  
  const getMiniGameAnswerTitle = () => {
    switch (SessionState.currentMiniGame) {
      case EMiniGame.BlankOMatic: {
        return "FILL IN THE BLANK!";
      }
      case EMiniGame.SubTheTitle: {
        return "COMPLETE THE SUBTITLE!";
      }
      case EMiniGame.BookIt: {
        return "TITLE THIS BOOK!";
      }
      case EMiniGame.SignMeUp: {
        return "ENTER YOUR ANSWER!";
      }
      case EMiniGame.ExtraExtra: {
        return "WRITE A HEADLINE OR CAPTION!";
      }
      case EMiniGame.SurveySays: {
        return "ENTER YOUR ANSWER!";
      }
      case EMiniGame.OutOfTheQuestion: {
        return "ENTER YOUR ANSWER!";
      }
      case EMiniGame.Letterheads: {
        return "FILL IN THE INITIALS!";
      }
      default: {
        return "ENTER YOUR ANSWER!";
      }
    }
  };

  const getActivityTitle = () => {
    switch (SessionState.currentActivity) {
      case EActivity.PredictTheWinner: {
        return 'Predict The Winner!';
      }
      case EActivity.Superlatives: {
        return 'Superlatives';
      }
      case EActivity.UseYourWordsTrivia: {
        return 'Use Your Words Trivia';
      }
      case EActivity.ClassicGameShowTrivia: {
        return 'Classic Game Show Trivia';
      }
      case EActivity.TheSpectatorsGiveth: {
        return 'The Spectators Giveth';
      }
      case EActivity.TheSpectatorsTaketh: {
        return 'The Spectators Taketh Away';
      }
      case EActivity.SpectatorPoll: {
        return 'Spectator Poll';
      }
    }
    return '';
  };

  const getPage = () => {
    SessionState.currentPage = page;
    switch (page) {
      case EPage.Enter:
        return (
          <div>
            {getHeader('JOIN ROOM')}
            <RoomCodePage
              socket={socket}
              setPage={setPage}
              setError={setError}
            />
          </div>
        );
      case EPage.Login:
        return (
          <div>
            {getHeader('JOIN ROOM')}
            <LoginPage socket={socket} setPage={setPage} setError={setError} />
          </div>
        );
      case EPage.Waiting: {
        const title = getWaitingTitle();
        return (
            <div>
              {getHeader(title)}
              <WaitingPage />
            </div>
        );
      }
      case EPage.Answer:
        return (
          <div>
            {getHeader(getMiniGameAnswerTitle())}
            <AnswerPage socket={socket} setPage={setPage} setError={setError} />
          </div>
        );
      case EPage.Vote:
        return (
          <div>
            {getHeader('VOTE FOR YOUR FAVORITE!')}
            <VotePage socket={socket} setPage={setPage} setError={setError} />
          </div>
        );
      case EPage.SpectatorsWelcome:
        return (
          <div>
            {getHeader('WELCOME, SPECTATOR!')}
            <SpectatorsWelcomePage />
          </div>
        );
      case EPage.Activity:
        return (
          <div>
            {getHeader(getActivityTitle())}
            <ActivityPage
              socket={socket}
              setPage={setPage}
              setError={setError}
            />
          </div>
        );
      case EPage.Reaction:
        return (
          <div>
            {getHeader('SPECTATOR AWARDS')}
            <ReactionPage
              socket={socket}
              setPage={setPage}
              setError={setError}
            />
          </div>
        );
      case EPage.ThankYou:
        return (
          <div>
            {getHeader('THANKS FOR PLAYING!')}
            <ThankYouPage />
          </div>
        );
      case EPage.WaitingPostAnswer:
        return (
          <div>
            {getHeader('WAITING FOR OTHER PLAYERS')}
            <WaitingPostAnswerPage socket={socket} setPage={setPage} setError={setError} />
          </div>
        );
      case EPage.Gizmo:
        return (
          <div>
            {getHeader('USE A GIZMO')}
            <GizmoPage socket={socket} setPage={setPage} setError={setError} />
          </div>
        );
    }
  };

  const isGameDown = false;
  
  return (
      <div>
        {isGameDown ? (
            <div>
              <div className="container box">
                <div className="notification is-danger mb-4">
                  Whoa! Our servers are struggling to handle all the people trying to play. But don’t worry,
                  we’re on it. Try again in a little while!
                </div>
              </div>
            </div>
        ) : (
            <div>
              <div className="container box">
                <ErrorNotification error={error} setError={setError} />
                {getPage()}
              </div>
              <div className="container is-pulled-right">
                <br />
              </div>
            </div>
        )}
        <a href="https://useyourwords.lol/help" target="_blank" className="help">Having trouble?</a>
      </div>
  );
}

export default App;
