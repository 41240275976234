import SessionState from '../../SessionState';

const possibleTips = [
    "If your browser seems to be stuck, try reloading the page to jump back into the game!",
    "Making video content? Try our copyright-safe music soundtrack with \"Streamer Mode\" in the Settings Menu.",
    "DID YOU KNOW? Gizmos are only available in the first six rounds of a match.",
    "If a disconnected player doesn't want to play anymore, hit \"Clear Disconnected\" in the Pause Menu.",
    "It's called a \"house decoy\" because we give you a house answer disguised as an original answer.",
    "An individual player can only use a Gizmo once per match. Once any player uses a Gizmo, no one else can use one until the next round.",
    "If your player flag says \"disconnected\", you may just need to wake up your phone or tablet.",
    "If you want to control who can join your game, enter a custom password in the Settings Menu!",
    "If your player flag says \"disconnected\", you may need to reload the page on your browser.",
    "DID YOU KNOW? The timer doesn't start until at least one player has submitted an answer.",
    "If you'd rather not see \"adult\" prompts and house answers, turn on \"Family Mode\" in the Settings Menu.",
    "You can't unlock Mix n' Match Mode until you've completed one Classic Party Match AND one New Party Match.",
    "If the timer runs out before you submit your answer, we automatically give you a house decoy.",
    "If more than 6 people want to play, turn on \"Spectator Mode\" in the Settings menu.",
    "DID YOU KNOW? Every prompt has at least seven house answers. When you press \"House Decoy\", we randomly select one for you to use.",
    "Inside jokes among your player group are more likely to get votes since they won't be mistaken for House Answers.",
    "If you accidentally vote for a House answer or a House decoy, you'll lose 250 points (even more in lightning rounds!)",
    "If you get a short \"Sub The Title\" prompt, try keeping your answer short so others have time to read it.",
    "Sometimes, the simplest answer ends up being everyone's favorite.",
    "Some house Answers are, written with bad grammer; or mispellings",
    "In \"Spectator Mode\", up to 1000 people can join in the fun. Find it in the settings menu!"
];

const getRandomTip = () => {
    return possibleTips[Math.floor(Math.random() * possibleTips.length)];
};

function TipsBox() {
    if (SessionState.isPlayer()) {
        return (
            <div className="tips">
                <h1 className="title_tips">Tip</h1>
                {getRandomTip()}
            </div>
        );
    } else {
        return (
            <div></div>
        );
    }
}

export default TipsBox;
