import React, { useState } from 'react';
import PageProps from 'src/components/pages/PageProps';
import SubmitButton from 'src/components/common/SubmitButton';
import SubmittableInput from '../common/SubmittableInput';
import { EPage } from './EPage';
import { ENetworkEvent } from '../../network/enums/ENetworkEvent';
import sendRequest, { RequestError } from '../../network/SendRequest';
import { getErrorMessageForCode } from '../../network/ErrorMessages';
import SessionState from '../../SessionState';
import { GizmoResponse } from '../../GizmoResponse';
import { EGizmoReason } from '../../enums/EGizmoReason';
import SubmittableTextArea from '../common/SubmittableTextArea';

function AnswerPage({ socket, setPage, setError }: PageProps) {
  const initialAnswers = SessionState.answerStartWithRequirements ?? [''];
  const [answers, setAnswers] = useState<string[]>(initialAnswers);
  const submitRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;

  const clearError = () => {
    setError(undefined);
  };

  const setAnswer = (index: number, value: string) => {
    if (index < answers.length) {
      const newAnswers = answers.slice();
      newAnswers[index] = value;
      setAnswers(newAnswers);
    }
  };

  const areValidAnswers = () => {
    for (let i = 0; i < answers.length; i++) {
      const answer = answers[i];
      const initialAnswer = initialAnswers[i];
      if (answer.length <= initialAnswer.length) {
        return false;
      }
    }

    return true;
  };

  const sendAnswer = async () => {
    if (areValidAnswers()) {
      try {
        const response: GizmoResponse = await sendRequest(
          socket,
          ENetworkEvent.SendAnswer,
          { room_code: SessionState.roomCode, answers },
          5000,
        );

        SessionState.gizmoReason = response.gizmo_reason;
        SessionState.players = response.players;
        // We might already be in Waiting if the player was last to answer
        if (SessionState.currentPage === EPage.Answer) {
          if (SessionState.gizmoReason === EGizmoReason.InvalidState) {
            setPage(EPage.Waiting);
          } else {
            setPage(EPage.WaitingPostAnswer);
          }
        }
        clearError();
      } catch (e) {
        const error = e as RequestError;
        setError(getErrorMessageForCode(error.error_code));
      }
    }
  };

  const sendDecoy = async () => {
    try {
      const response: GizmoResponse = await sendRequest(
        socket,
        ENetworkEvent.SendAnswer,
        { room_code: SessionState.roomCode, answers: null },
        5000,
      );
      SessionState.gizmoReason = response.gizmo_reason;
      SessionState.players = response.players;
      // We might already be in Waiting if the player was last to answer
      if (SessionState.currentPage === EPage.Answer) {
        if (SessionState.gizmoReason === EGizmoReason.InvalidState) {
          setPage(EPage.Waiting);
        } else {
          setPage(EPage.WaitingPostAnswer);
        }
      }
    } catch (e) {
      const error = e as RequestError;
      setError(getErrorMessageForCode(error.error_code));
    }
  };

  const renderInputs = () => {
    const numberOfAnswers = SessionState.answerStartWithRequirements?.length ?? 1;
    return (
      <div className="field">
        {
          numberOfAnswers === 1 ?
              SessionState.answerStartWithRequirements?.map((value, index) => (
                    <SubmittableTextArea
                        key={index}
                        button={null}
                        placeholder="ANSWER HERE"
                        autoFocus={index === 0}
                        onChange={(event) => setAnswer(index, event.target.value)}
                        startsWith={value}
                        maxLength={200 / numberOfAnswers}
                    />
                )) :
              SessionState.answerStartWithRequirements?.map((value, index) => (
                  <SubmittableInput
                      key={index}
                      button={null}
                      type="text"
                      placeholder="ANSWER HERE"
                      autoFocus={index === 0}
                      onChange={(event) => setAnswer(index, event.target.value)}
                      startsWith={value}
                      maxLength={200 / numberOfAnswers}
                  />))}
      </div>
    );
  };

  return (
    <div>
      {renderInputs()}
      <div>
        <SubmitButton
		  additionalClasses={"button is-primary"}
          text={'House Decoy'}
          enabled={true}
          alignRight={false}
          onClick={sendDecoy}
        />
        &nbsp;&nbsp;
        <SubmitButton
          ref={submitRef}
          text={'Submit'}
          enabled={areValidAnswers()}
          alignRight={true}
          onClick={sendAnswer}
        />
      </div>
    </div>
  );
}

export default AnswerPage;
