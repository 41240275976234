import Spinner from 'src/components/common/Spinner';
import TipsBox from 'src/components/common/TipsBox';

function WaitingPage() {
  return (
      <div>
          <div className="mb-4">
              <Spinner />
              <br />
          </div>
          <TipsBox />
      </div>
  );
}

export default WaitingPage;
