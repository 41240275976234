export enum EMiniGame {
    BlankOMatic,
    SubTheTitle,
    BookIt,
    SignMeUp,
    ExtraExtra,
    SurveySays,
    OutOfTheQuestion,
    Letterheads,
}
