import Spinner from 'src/components/common/Spinner';
import SubmitButton from '../common/SubmitButton';
import React from 'react';
import sendRequest, { RequestError } from '../../network/SendRequest';
import { ENetworkEvent } from '../../network/enums/ENetworkEvent';
import SessionState from '../../SessionState';
import { EPage } from './EPage';
import { getErrorMessageForCode } from '../../network/ErrorMessages';
import PageProps from './PageProps';
import { GizmoResponse } from '../../GizmoResponse';
import TipsBox from '../common/TipsBox';
import { EGizmoReason } from '../../enums/EGizmoReason';

function WaitingPostAnswerPage({ socket, setPage, setError }: PageProps) {
  const clearError = () => {
    setError(undefined);
  };
  
  const useGizmo = async () => {
    try {
      const response: GizmoResponse = await sendRequest(
        socket,
        ENetworkEvent.RequestGizmo,
        { room_code: SessionState.roomCode },
        5000,
      );

      // TODO: We might not need this second round of GizmoResponseData depending on UX flow. Might just 
      // need to send canUseGizmo again.
      SessionState.gizmoReason = response.gizmo_reason;
      SessionState.players = response.players;
      
      if (response.gizmo_reason === EGizmoReason.None) {
        setPage(EPage.Gizmo);
      } else {
        setPage(EPage.WaitingPostAnswer);
      }
      
      clearError();
    } catch (e) {
      const error = e as RequestError;
      setError(getErrorMessageForCode(error.error_code));
    }
  }
  
  const renderGizmoReason = () => {
    switch (SessionState.gizmoReason) {
      case EGizmoReason.None:
      case EGizmoReason.InvalidState: {
        return '';
      }
      case EGizmoReason.GizmoUsedThisRound: {
        return 'A Gizmo was already used this round.';
      }
      case EGizmoReason.GizmoUsedByPlayer: {
        return 'You\'ve already used a Gizmo this match.';
      }
    }
  };

  return (
      <div>
        <div className="mb-4">
          <Spinner />
          <br />
          <br />
            <SubmitButton
                text={'Use Gizmo'}
                enabled={SessionState.gizmoReason === EGizmoReason.None}
                alignRight={true}
                onClick={useGizmo}
            />
          {renderGizmoReason()}
        </div>
        <div style={{marginTop: '75px'}}>
          <TipsBox />
        </div>
      </div>
  );
}

export default WaitingPostAnswerPage;
