import React, { useState } from 'react';
import PageProps from 'src/components/pages/PageProps';
import SubmitButton from 'src/components/common/SubmitButton';
import { EPage } from './EPage';
import { ENetworkEvent } from '../../network/enums/ENetworkEvent';
import sendRequest, { RequestError } from '../../network/SendRequest';
import { getErrorMessageForCode } from '../../network/ErrorMessages';
import SessionState from '../../SessionState';
import { EGizmo } from '../../enums/EGizmo';
import { EGizmoReason } from '../../enums/EGizmoReason';

function GizmoPage({ socket, setPage, setError }: PageProps) {
  const [gizmo, setGizmo] = useState<number>();
  const [target, setTarget] = useState<number>();
  const buttonRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;

  const clearError = () => {
    setError(undefined);
  };

  const isValidGizmo = () => {
    if (!!gizmo && gizmo > 0) {
      if (gizmo === EGizmo.ShareTheWealth) {
        return !!target && target > 0;
      } else {
        return true;
      }
    }
    return false;
  };
  
  const cancel = async () => {
    setPage(EPage.WaitingPostAnswer);
  };

  const sendGizmo = async () => {
    if (isValidGizmo()) {
      try {
        await sendRequest(
          socket,
          ENetworkEvent.SendGizmo,
          { room_code: SessionState.roomCode, gizmo, target },
          5000
        );
        
        SessionState.gizmoReason = EGizmoReason.InvalidState;
        setPage(EPage.Waiting);
        
        clearError();
      } catch (e) {
        const error = e as RequestError;
        setError(getErrorMessageForCode(error.error_code));
      }
    }
  };

  const renderGizmos = () => {
    return (
      <div className="field">
        <label className="title_gizmos">Choose a Gizmo</label>
        <div>
          <input id="gizmo1" className="gizmo gizmo1" type="radio" name="gizmos" onChange={() => {
            setGizmo(EGizmo.ShareTheWealth);
            setTarget(0);
          }} />
          <label htmlFor="gizmo1"></label>
          <input id="gizmo3" className="gizmo gizmo3" type="radio" name="gizmos" onChange={() => {
            setGizmo(EGizmo.DoubleDown);
            setTarget(undefined);
          }} />
          <label htmlFor="gizmo3"></label>
          <input id="gizmo2" className="gizmo gizmo2" type="radio" name="gizmos" onChange={() => {
            setGizmo(EGizmo.OnTheHouse);
            setTarget(undefined);
          }} />
          <label htmlFor="gizmo2"></label>
        </div>
      </div>
    );
  };
  
  const renderGizmoTitle = () => {
    switch (gizmo) {
      case undefined:
      case EGizmo.None: {
        return (<div className={"title_gizmos"}>No Gizmo Chosen</div>);
      }
      case EGizmo.ShareTheWealth: {
        return (<div className={"title_gizmos"}>Use Their Words</div>);
      }
      case EGizmo.DoubleDown: {
        return (<div className={"title_gizmos"}>Double Down</div>);
      }
      case EGizmo.OnTheHouse: {
        return (<div className={"title_gizmos"}>On The House</div>);
      }
    }
  }
  
  const renderGizmoDescription = () => {
    switch (gizmo) {
      case undefined:
      case EGizmo.None: {
        return (<div>Select a Gizmo to use!</div>);
      }
      case EGizmo.ShareTheWealth: {
        return (<div>Earn points when another player gets points this round!</div>);
      }
      case EGizmo.DoubleDown: {
        return (<div>Double the amount of points you get this round!</div>);
      }
      case EGizmo.OnTheHouse: {
        return (<div>Guess the house answer (or decoy) to earn extra points!</div>);
      }
    }
  }
  
  const renderTargets = () => {
    let showTargets = false;
    if (gizmo === EGizmo.ShareTheWealth) {
      showTargets = true;
    }
    
    if (showTargets) {
      return (
        <div>
          <label className="label">Choose a Target</label>
          <div className="control">
            {
              SessionState.players?.filter(player => player.id != SessionState.playerId)
                .map((player, index) =>
              {
                return (
                  <p key={index}>
                    <label className="b-radio custom_radio is-large">
                      <input
                        type="radio"
                        name="answers"
                        onChange={() => {
                          setTarget(player.id);
                        }}
                        checked={target === player.id}
                      />
                      <span className="checkmark"></span>
                      &nbsp;{player.name}
                    </label>
                  </p>
                );
              })
            }
          </div>
        </div>
      );
    } else {
      return (<div></div>);
    }
  };

  return (
    <div>
	    <div className="gizmo_box">
      <div className="field">{renderGizmos()}</div>
      <div className="field">{renderGizmoTitle()}</div>
      <div className="field">{renderGizmoDescription()}</div>
      <div className="field">{renderTargets()}</div>
	  </div>
      <div>
        <SubmitButton
          text={'Cancel'}
          additionalClasses={"button is-primary"}
          enabled={true}
          alignRight={false}
          onClick={cancel}
        />
        &nbsp;&nbsp;
        <SubmitButton
          ref={buttonRef}
          text={"Submit"}
          enabled={isValidGizmo()}
          alignRight={true}
          onClick={sendGizmo}
        />
      </div>
    </div>
  );
}

export default GizmoPage;
